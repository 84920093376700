import { Controller } from '@hotwired/stimulus';
import Editor from './ckeditor';
import { useMeta } from 'stimulus-use';

export default class extends Controller {
    static values = {
        options: {
            type: Object,
            default: {},
        },
    };

    static targets = [
        'editor',
        'input',
    ];

    initialize () {
        useMeta(this);
    }

    async connect () {
        Editor.create(this.element.querySelector('.ckeditor'), this.optionsValue)
            .catch(error => {
                console.log(error);
            });
    }
}
